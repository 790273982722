import React from "react";
import { Container } from "semantic-ui-react";

import LayoutHome from "../../components/layout/layout-home";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import PlaceHolderContent from "../../components/placeholder-content";

const Page = () => (
  <LayoutHome>
    <SEO title="Primary" keywords={[`rocksteady`]} />

    <Container>
      <SecondaryNavBar
        product="primary"
        title="Rocksteady Primary"
        links={[
          {
            name: "Info for Parents",
            path: "/primary/parents",
            active: true
          },
          {
            name: "Info for Schools",
            path: "/primary/schools",
            active: false
          }
        ]}
        cta="Enrol Today"
      />
      <PlaceHolderContent title="Primary Parents Info" />
    </Container>
  </LayoutHome>
);

export default Page;
